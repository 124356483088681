import { useConsentForm, FeedbackMessage, Logger } from '@adatree/atomic-components';
import {
  consentApi,
  useCaseApi,
  CreateConsent,
  UseCaseResponse,
  ConsentResponse,
  PostUsageAction,
  ConsentsList,
} from '@adatree/react-api-sdk';
import { ReactNode, useEffect, useState } from 'react';
import { Box, Skeleton } from '@mui/material';
import { AuthUtil } from '../../../utils/auth/auth.util';
import { State } from '../../../app/state/state';
import { useAppSettings } from '../../../providers/app-settings.provider';
import { ConsentCreateOneUseCase } from './consent-create-one-use-case.organism';
import { ConsentCreateManyUseCase } from './consent-create-many-use-case.organism';
import { handleErrorRedirect, trackError } from '../../../utils/errors/errors.util';
import { URL_SETTINGS } from '../../../app/settings/url.settings';
import { AuthType } from '../../../context/authentication/auth.context';
import { CONSTANTS } from '../../../app/consts/app.const';
import { v4 as uuidv4 } from 'uuid';
import AlertCircle from 'mdi-material-ui/AlertCircle';
import Check from 'mdi-material-ui/Check';

interface Props {
  dataHolderId?: string;
  useCaseId?: string;
}

export const ConsentCreate = ({ dataHolderId, useCaseId }: Props) => {
  const {
    createConsent,
    isMutating: isCreateConsentMutating,
    error: createConsentError,
  } = consentApi.useCreateConsentTrigger();
  const { getConsents } = consentApi.useGetConsentsV2Trigger();
  const {
    postConsentAuthorization,
    isMutating: isConsentAuthMutating,
    error: consentAuthError,
  } = consentApi.usePostConsentAuthorizationTrigger();
  const { getUseCases } = useCaseApi.useGetUseCasesTrigger();

  const [consentForm, setConsentForm] = useConsentForm();
  const [useCase, setUseCase] = useState<UseCaseResponse>();
  const [useCases, setUseCases] = useState<UseCaseResponse[]>();
  const [useCaseIdFromApi, setUseCaseIdFromApi] = useState<string>();
  const [existingConsents, setExistingConsents] = useState<ConsentResponse[]>();
  const [feedback, setFeedback] = useState<ReactNode>();
  const [redirect, setRedirect] = useState<string | undefined>();
  const { appSettings } = useAppSettings();

  useEffect(() => {
    setConsentForm((prevState) => {
      return {
        ...prevState,
        useCaseId: useCaseIdFromApi,
      };
    });
  }, [useCaseIdFromApi, setConsentForm]);

  useEffect(() => {
    if (!isCreateConsentMutating && createConsentError) {
      Logger.error('Create consent error', createConsentError);
      renderFeedback('Sorry we were not able to process your request. Please try again later.', false, true);
    }
  }, [isCreateConsentMutating, createConsentError]);

  useEffect(() => {
    if (redirect && !isConsentAuthMutating && !consentAuthError) {
      window.location.href = redirect;
    } else if (consentAuthError) {
      Logger.error('Consent authorization error', consentAuthError);
      renderFeedback('Sorry we were not able to process your request. Please try again later.', false, true);
    }
  }, [redirect, isConsentAuthMutating, consentAuthError]);

  /**
   * Handlers
   */
  const handleUseCaseChange = (useCaseId) => {
    const foundUseCase = useCases.filter((useCase) => {
      return useCase.id === useCaseId;
    });

    consentForm.useCaseId = useCaseId;
    setConsentForm({ ...consentForm });
    setUseCase(foundUseCase[0]);
  };

  const handleUserCancel = () => {
    const errorCode = CONSTANTS.errors.userOptOut.code;
    const errorMessage = CONSTANTS.errors.userOptOut.description;

    if (appSettings.consent.errorRedirectUrl) {
      handleErrorRedirect(appSettings.consent.errorRedirectUrl, errorCode, errorMessage);
    } else {
      Logger.info(errorMessage, `Error code: ${errorCode}`);
      window.location.href = URL_SETTINGS.CONSENT_CREATE.url;
    }
  };

  /**
   * Submit the form
   */
  const handleSubmit = () => {
    let email = AuthUtil.getEmail(appSettings);
    let mobileNumber = AuthUtil.getMobileNumber(appSettings);

    if (email === '' && mobileNumber === '') {
      const errorMsg = 'User email and mobile not set';
      Logger.error(errorMsg);
      trackError(new Error(errorMsg));
    }

    let consent: CreateConsent = {
      consumerEmail: email,
      dataHolderBrandId: consentForm.dataHolder.dataHolderBrandId,
      directMarketingAllowed: false,
      postUsageAction: PostUsageAction.Deletion,
      sharingEndDate: consentForm.sharingEndDate.toISOString(),
      useCaseId: consentForm.useCaseId,
      customData: consentForm.customData,
    };

    if (State.getAuthType() === AuthType.OTP) {
      // @ts-ignore
      let granteeId = State.getOtpAuthManager().granteeId;
      if (granteeId) {
        consent.grantee = { id: granteeId, name: '', licenceNumber: '' };
      }
    }

    const createdConsent = async () => {
      renderFeedback('Saving your consent request...', true);
      const createdConsent = await createConsent({ createConsent: consent });

      renderFeedback(`Please wait while we redirect you to ${consentForm.dataHolder.brandName}`);
      const state = uuidv4();

      // Store the state and redirect Uri for future security check
      sessionStorage.setItem(state, appSettings.api.adhRedirectUri);
      sessionStorage.setItem(CONSTANTS.storageKeys.authState, state);
      sessionStorage.setItem(CONSTANTS.storageKeys.authConsentId, createdConsent.consentId);
      sessionStorage.setItem(CONSTANTS.storageKeys.authType, State.getAuthType());

      const redirect = await postConsentAuthorization({
        consentId: createdConsent.consentId,
        consentAuthorizationRequest: {
          state: state,
          redirectUri: appSettings.api.adhRedirectUri,
          sharingEndDate: createdConsent.sharingEndDate
        }
      });

      setRedirect(redirect.uri);
    };

    createdConsent();
  };

  /**
   * Feedback
   */
  const renderFeedback = (message: string, isLoading = false, isError = false) => {
    const icon =
      isError === true ? (
        <AlertCircle sx={{ fontSize: '56px', color: 'error.main' }} />
      ) : (
        <Check sx={{ fontSize: '56px', color: 'primary.main' }} />
      );

    setFeedback(<FeedbackMessage message={message} icon={icon} showSpinner={isLoading} />);
  };

  /**
   * Call the APIs
   */

  useEffect(() => {
    const callApis = async () => {
      await getUseCases({ active: true, combineScopes: true })
        .then((foundUseCases: UseCaseResponse[]) => {
          setUseCases(foundUseCases);

          if (useCaseId) {
            const useCaseIndex = foundUseCases.findIndex((useCase) => {
              return useCase.id === useCaseId;
            });
            if (useCaseIndex > -1) {
              setUseCase(foundUseCases[useCaseIndex]);
              setUseCaseIdFromApi(foundUseCases[useCaseIndex].id);
            } else {
              Logger.error(`Use case ID "${useCaseId}" does not exist in use cases:`, foundUseCases);
            }
          }

          if (foundUseCases.length === 1) {
            setUseCase(foundUseCases[0]);
            setUseCaseIdFromApi(foundUseCases[0].id);
          } else if (foundUseCases.length === 0) {
            setFeedback(
              'No use cases found. Please check that there is at least one use case associated with this tenant.'
            );
          }
        })
        .catch((error) => {
          // This error needs to be handled
          Logger.error('Error calling getUseCases API', error);
          throw new Error(error);
        });
    };

    callApis();
  }, [getUseCases, useCaseId, setUseCaseIdFromApi]);

  useEffect(() => {
    const callGetConsents = async () => {
      getConsents({})
        .then((consents: ConsentsList) => {
          setExistingConsents(consents.consents);
        })
        .catch((error) => {
          // This error needs to be handled
          Logger.error('Error calling consentRepository.findAll()', error);
          throw new Error(error);
        });
    };

    callGetConsents();
  }, [getConsents]);

  /**
   * Render
   */
  return (
    <Box>
      {feedback && feedback}
      {!feedback && !useCases && (
        <>
          <Skeleton sx={{ mb: 4.2, height: '3.5rem' }} />
          <Skeleton sx={{ mb: 2, height: '4rem' }} />
          <Skeleton sx={{ m: 1, height: '12rem' }} />
        </>
      )}
      {!feedback && useCase && existingConsents && (
        <ConsentCreateOneUseCase
          existingConsents={existingConsents}
          useCase={useCase}
          dataHolderId={dataHolderId}
          onSubmit={handleSubmit}
          onCancel={handleUserCancel}
        />
      )}
      {!feedback && !useCase && useCases && useCases.length > 1 && (
        <ConsentCreateManyUseCase useCases={useCases} onChange={handleUseCaseChange} onCancel={handleUserCancel} />
      )}
    </Box>
  );
};
